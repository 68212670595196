@use '../../styles/mixins/text.mixin' as text;
@use 'colors' as colors;

:host {
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  padding: 17px 41px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow: visible;

  .logo {
    height: 40px;
    margin-right: 30px;

    img {
      height: 100%;
    }
  }

  .hub-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 5px;

    .breadcrumb-icon {
      color: colors.$color-neutral-N600;
    }

    .hub-link {
      color: colors.$color-neutral-N600;
      text-decoration: none;

      &.no-click {
        color: colors.$color-neutral-N900;

        &:visited,
        &:active {
          color: inherit;
        }
      }
    }
  }
}

.video-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: colors.$color-neutral-N900;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 5px;
}

.back-btn {
  display: none;
  font-weight: bold;

  mat-icon {
    margin: 0;
  }
}

.no-click {
  pointer-events: none;
  cursor: unset;
}

.header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    padding: 8px 16px;
    background: #0066ff;
    border: unset;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
  }

  .user-details {
    @include text.text-basic();
    display: flex;
    gap: 12px;

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .username {
      display: flex;
      gap: 15px;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #101828;
      cursor: pointer;

      mat-icon {
        width: 15px;
        height: 15px;
        font-size: 15px;
      }
    }
  }
}

.menu-item {
  --mat-menu-item-label-text-size: 12px;
  height: 25px;
  min-height: 25px;
  line-height: 25px;
}

@media screen and (max-width: 960px) {
  .breadcrumbs {
    flex-direction: column;
    align-items: flex-start;

    .breadcrumb-icon {
      display: none;
    }

    .video-title {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    padding: 15px 20px;
  }

  .header-actions .user-details {
    gap: 6px;

    .username {
      gap: 6px;
    }
  }

  .logo-container {
    margin: 0;
    border-radius: 0;

    .logo {
      height: 25px;
      margin-right: 10px;
    }

    .logo-details {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 520px) {
  .header-actions .user-details {
    gap: 6px;

    .username {
      gap: 6px;
    }
  }

  .logo-container {
    margin-bottom: 0;
    box-shadow: none;

    .logo-details {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .hub-title,
  .video-title {
    display: none !important;
  }

  .back-btn {
    display: flex;
  }
}
