@use 'colors' as colors;

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: colors.$color-neutral-N100;

  .left {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  span,
  a {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8f95b2;
    text-decoration: none;
  }
}
